import { Component, DestroyRef, inject, OnInit, signal, ViewChild } from '@angular/core';
import { CL_ICON, CL_SIZE, CL_INFO_MODAL_TYPES, ClTableModule } from '@circlek-global/eca-lightning-components';
import { ClIconModule } from '@circlek-global/eca-lightning-components';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { ApiService } from '../api/api.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderService } from '../components/loader/loader.service';
import { finalize } from 'rxjs';
import { ToastService } from '../components/toast/toast.service';

import reportSentryError from '../../helpers/reportSentryError';
import { ModalBaseComponent } from '../components/modal-base/modal-base.component';

type DataSourceTermsType = {
  codeName: string;
  countryCode: string;
  version: number;
  status: string;
  url: string;
  description: string | null;
};

@Component({
  selector: 'app-user-terms',
  standalone: true,
  imports: [ClTableModule, ClIconModule, TranslateModule, ModalBaseComponent],
  templateUrl: './user-terms.component.html',
})
export class UserTermsComponent implements OnInit {
  @ViewChild(ModalBaseComponent) modalBaseComponent!: ModalBaseComponent;
  private httpClient = inject(HttpClient);
  private destroyRef = inject(DestroyRef);
  private authService = inject(AuthService);
  private apiService = inject(ApiService);
  private toast = inject(ToastService);
  CL_INFO_MODAL_TYPES = CL_INFO_MODAL_TYPES;

  loader = inject(LoaderService);

  termsDataSource = signal<DataSourceTermsType[]>([]);

  termsToDelete: {
    terms: string;
    country: string;
  } = {
    terms: '',
    country: '',
  };

  trashIcon = CL_ICON.TRASH_01;
  linkIcon = CL_ICON.LINK_01;
  CL_SIZE = CL_SIZE;

  ngOnInit(): void {
    this.getUserTerms();
  }

  getUserTerms() {
    this.loader.setLoader(true);
    const subscription = this.httpClient
      .get<DataSourceTermsType[]>(`${this.apiService.createBffUrl({ path: 'terms', useRegion: true })}`, {
        withCredentials: true,
      })
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: (responseData) => {
          this.termsDataSource.set(responseData);
        },
        error: (error) => {
          reportSentryError({
            error,
            customErrorName: 'Get User Terms Error',
            endpointName: 'get-user-terms',
          });

          console.error({ error });
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  deleteTerms() {
    this.loader.setLoader(true);

    const subscription = this.httpClient
      .delete(`${this.apiService.createBffUrl({ path: 'terms', useRegion: true })}`, {
        withCredentials: true,
        body: {
          codeName: this.termsToDelete.terms,
          countryCode: this.termsToDelete.country,
        },
      })
      .pipe(
        finalize(() => {
          this.loader.setLoader(false), this.onButtonClose();
        }),
      )
      .subscribe({
        next: () => {
          if (this.termsToDelete.terms == 'terms_extra') {
            this.authService.logout();
            return;
          }

          this.termsDataSource.update((items: DataSourceTermsType[]) =>
            items.filter((item: DataSourceTermsType) => item.codeName !== this.termsToDelete.terms),
          );

          this.toast.showSuccess('TERMS_DELETE_SUCCESS');
        },
        error: (error) => {
          reportSentryError({
            error,
            customErrorName: 'Delete User Terms Error',
            endpointName: 'delete-user-terms',
          });

          console.error({ error });
          this.toast.showError('something_went_wrong');
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  onButtonClose() {
    this.termsToDelete.terms = '';
    this.termsToDelete.country = '';
  }

  onButtonConfirm() {
    this.deleteTerms();
  }

  openDeleteModal(terms: string, country: string) {
    this.termsToDelete.terms = terms;
    this.termsToDelete.country = country;
    this.modalBaseComponent.openModal();
  }
}
