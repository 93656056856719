import {Component, EventEmitter, inject, Input, Output,} from '@angular/core';
import {
  CL_INFO_MODAL_TYPES, ClInfoModalComponent,
  ClModalModule, ClModalService, InfoModalData
} from '@circlek-global/eca-lightning-components';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [ClModalModule],
  templateUrl: './modal-base.component.html'
})
export class ModalBaseComponent {
  private modalService = inject(ClModalService);
  private translate = inject(TranslateService);

  @Input() modalType!: CL_INFO_MODAL_TYPES
  @Input() titleTransKey!: string;
  @Input() infoTextTransKey!: string;
  @Input() confirmBtnTextTransKey!: string;
  @Input() cancelBtnTextTransKey!: string;
  @Input() isCancelBtnHidden!: boolean;

  @Output() onButtonClose = new EventEmitter();
  @Output() onButtonConfirm = new EventEmitter();

  openModal(){
    this.modalService.open<ClInfoModalComponent, InfoModalData>(ClInfoModalComponent, {
      data: {
        type: this.modalType,
        title: this.translate.instant(this.titleTransKey),
        infoText: this.translate.instant(this.infoTextTransKey),
        confirmBtnText: this.translate.instant(this.confirmBtnTextTransKey),
        cancelBtnText: this.translate.instant(this.cancelBtnTextTransKey),
        isCancelBtnHidden: this.isCancelBtnHidden
      },
    }).onClose$.subscribe((decision) => {
      if(decision.data == true) {
        this.onButtonConfirm.emit();
      } else {
        this.onButtonClose.emit();
      }
    });
  }
}
