import { Component, EventEmitter, inject, Input, OnInit, Output, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  CL_BUTTON_EXPAND,
  CL_BUTTON_VARIANT,
  CL_INFO_MODAL_TYPES,
  CL_SIZE,
  ClButtonModule,
  ClButtonSize,
  ClInputModule,
  ClNotificationMessageModule,
} from '@circlek-global/eca-lightning-components';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import {nameValidator, phoneBodyValidator, phonePrefixValidator} from 'src/helpers/validators';
import { ApiService } from '../api/api.service';
import { LoaderComponent } from '../components/loader/loader.component';
import { UserData } from '../state/user/user.model';
import { selectUserData } from '../state/user/user.selectors';
import { ModalBaseComponent } from '../components/modal-base/modal-base.component';
import {combineLatest, merge} from "rxjs";

@Component({
  selector: 'app-user-data-form',
  standalone: true,
  imports: [
    TranslateModule,
    ClButtonModule,
    ClInputModule,
    ClNotificationMessageModule,
    ReactiveFormsModule,
    LoaderComponent,
    ModalBaseComponent,
  ],
  templateUrl: './user-data-form.component.html',
})
export class UserDataFormComponent implements OnInit {
  @Output() onSubmitButton = new EventEmitter<{ changeDataForm: FormGroup; userData: UserData | undefined }>();
  @Output() onCloseButton = new EventEmitter();
  @Input() closeButtonTransKey!: string;
  @Input() submitButtonTransKey!: string;
  @Input() requiredDataChange!: boolean;

  CL_INFO_MODAL_TYPES = CL_INFO_MODAL_TYPES;

  private store = inject(Store);
  private formBuilder = inject(FormBuilder);
  private apiService = inject(ApiService);

  user = signal<UserData | undefined>(undefined);

  closeDataChangeModeButtonVariants: CL_BUTTON_VARIANT = CL_BUTTON_VARIANT.SECONDARY;
  editDataButtonSize: ClButtonSize = CL_SIZE.XXL;
  editDataButtonExpand: CL_BUTTON_EXPAND = CL_BUTTON_EXPAND.FULL;

  @Output() verifyOtp = new EventEmitter<boolean>();

  isEuForm = this.apiService.isEuForm;
  changeDataForm!: FormGroup;

  ngOnInit() {
    this.getUserData();
  }

  private exactPhoneNumberPrefixAdnBody(userData: UserData | null): {phonePrefix?: string, phoneBody?: string} {
    const phoneUtil = PhoneNumberUtil.getInstance();

    try {
      const parsedNumber: PhoneNumber = phoneUtil.parse(userData?.phone);

      return {
        phonePrefix: `+${parsedNumber.getCountryCode()}`,
        phoneBody: parsedNumber?.getNationalNumber()?.toString()
      }
    } catch (error) {
      console.error('Error parsing phone number:', error);
    }

    return {};
  }

  getUserData() {
    this.store.select(selectUserData).subscribe((userData: UserData | null) => {
      const {phonePrefix, phoneBody} = this.exactPhoneNumberPrefixAdnBody(userData);

      this.user.set(userData || {});
      this.changeDataForm = this.formBuilder.group({
        email: [userData?.email, [Validators.required, Validators.email]],
        phonePrefix: [phonePrefix ?? '', [Validators.required, phonePrefixValidator()]],
        phoneBody: [phoneBody ?? '', [Validators.required, phoneBodyValidator()]],
      });

      if (this.isEuForm()) {
        this.changeDataForm.addControl(
          'firstName',
          this.formBuilder.control(userData?.firstName, [Validators.required, nameValidator()]),
        );
        this.changeDataForm.addControl(
          'lastName',
          this.formBuilder.control(userData?.lastName, [Validators.required, nameValidator()]),
        );
      }
    });
  }

  onSubmit() {
    this.onSubmitButton.emit({ changeDataForm: this.changeDataForm, userData: this.user() });
  }

  onClose() {
    this.onCloseButton.emit();
  }
}
