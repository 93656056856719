<app-layout>
  <div class="cl-container cl-mx-auto cl-max-w-xl cl-mt-32 cl-mb-32">
    <div class="cl-grid cl-justify-center">
      <h2 class="cl-text-display-lg cl-mb-12">{{ 'PERSONAL_DATA_CHANGE_TITLE' | translate }}</h2>
      @if (!isVerifyOtp) {
        <app-user-data-form (verifyOtp)="setVerifyOtp($event)"></app-user-data-form>
      } @else {
        <app-otp-form (verifyOtp)="setVerifyOtp($event)"> </app-otp-form>
      }
    </div>
  </div>
</app-layout>
