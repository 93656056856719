import { Component, Input, SimpleChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { passwordSchema } from 'src/helpers/validators';

type PasswordStrengthLevel = 0 | 1 | 2;

@Component({
  selector: 'app-password-strength-meter',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './password-strength-meter.component.html',
  styleUrl: './password-strength-meter.component.scss',
})
export class PasswordStrengthMeterComponent {
  @Input({ required: true }) password!: string;

  passwordStrengthLevel: PasswordStrengthLevel = 0;

  private checkPasswordStrengthLevel(password: string): PasswordStrengthLevel {
    const schemaErrors = [];

    Object.keys(passwordSchema).forEach((key) => {
      if (!passwordSchema[key].test(password)) schemaErrors.push(key);
    });

    const isValidPassword = !schemaErrors.length;

    if (isValidPassword && password.length > 12) return 2;
    if (isValidPassword) return 1;
    return 0;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.passwordStrengthLevel = this.checkPasswordStrengthLevel(changes.password.currentValue);
  }
}
