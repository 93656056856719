import {ChangedUserData, UserData, UserFormValues} from "../state/user/user.model";
import {finalize} from "rxjs";
import {inject, Injectable, signal, WritableSignal} from "@angular/core";
import {LoaderService} from "../components/loader/loader.service";
import {FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {ApiService, Region} from "./api.service";
import {ToastService} from "../components/toast/toast.service";
import reportSentryError from "../../helpers/reportSentryError";

@Injectable({ providedIn: 'root' })
export class UpdateProfileService {
  private loader = inject(LoaderService);
  private httpClient = inject(HttpClient);
  private apiService = inject(ApiService);
  private toast = inject(ToastService);
  isEuForm = signal(this.apiService.region() !== Region.US);

  private handlePhoneChanges(
    changes: Partial<ChangedUserData>,
    formValues: UserFormValues,
    userData: UserData | undefined
  ): void {
    if (changes.phonePrefix !== undefined || changes.phoneBody !== undefined) {
      const prefix = changes.phonePrefix ?? formValues.phonePrefix;
      const body = changes.phoneBody ?? formValues.phoneBody;

      changes.phone = `${prefix}${body}`;

      if (userData?.phone === changes.phone) {
        delete changes.phone;
      }

      delete changes.phonePrefix;
      delete changes.phoneBody;
    }
  }

  updateProfile(changeDataForm: FormGroup, userData: UserData | undefined, verifyOtp: WritableSignal<boolean>, onDataChangeSuccess: () => void) {
    this.loader.setLoader(true);

    const changes: Partial<ChangedUserData> = {};

    const formValues = changeDataForm.value;
    const user = userData ?? {};

    Object.keys(formValues).forEach((field: string) => {
      const newValue = formValues[field];

      if (newValue !== user[field as keyof UserData]) {
        changes[field as keyof ChangedUserData] = newValue;
        if (field === 'phonePrefix' || field === 'phoneBody') {
          this.handlePhoneChanges(changes, formValues, userData);
        }
      }
    });

    const phoneChanged = changes.phone !== undefined;
    const emailChanged = changes.email !== undefined;

    if (this.isEuForm()) {
      const lastNameChanged = changes.lastName !== undefined;
      const firstNameChanged = changes.firstName !== undefined;

      if ((phoneChanged || emailChanged) && (!firstNameChanged || !lastNameChanged)) {
        changes.firstName = user?.firstName;
      }
    }

    if(Object.keys(changes).length === 0) {
      this.loader.setLoader(false);
    }

    this.submitDataChange(changes, verifyOtp, onDataChangeSuccess);
  }

  private submitDataChange(changes: Partial<ChangedUserData>, verifyOtp: WritableSignal<boolean>, onDataChangeSuccess: () => void) {
    const subscription = this.httpClient
      .post(`${this.apiService.createBffUrl({ path: 'user', useRegion: true })}/update`, changes, {
        withCredentials: true,
      })
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: () => {
          if (changes.phone) {
            verifyOtp.set(true);
          } else {
            onDataChangeSuccess();
          }
        },
        error: (error) => {
          if (error.error.path === '/v1/me') {
            this.toast.showError('user_email_already_exists');
          } else {
            reportSentryError({
              error,
              customErrorName: 'On Change Data Submit Error',
              endpointName: 'on-change-data-submit',
            });

            this.toast.showError(error.error.errorCode);
          }
          console.error({ error });
        },
      });
  }
}
