/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

import { environment } from 'src/environments/environment';
import * as Sentry from "@sentry/angular";
import reportSentryError from "./helpers/reportSentryError";
import {getUserId} from "./helpers/getUserId";

Sentry.init({
  dsn: "https://905b7eb13dafd7151883cbcb116994f4@o1297331.ingest.us.sentry.io/4508104062009344",
  integrations: [],
  environment: environment.envName,
  debug: false,
  tracesSampleRate: 0.05,

  beforeSend(event) {
    if(!environment.logSentryErrors)
      return null;

    event.exception?.values?.forEach((exception) => {
      if(exception.stacktrace)
        delete exception.stacktrace
    })

    return event;
  }
});

Sentry.setUser({id: getUserId()});

bootstrapApplication(AppComponent, appConfig)
  .catch((error) => {
    reportSentryError({
      error,
      customErrorName: 'Bootstrap Application Error'
    });
    console.error(error);
  });
