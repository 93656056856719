import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, inject, OnInit, Output, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CL_BUTTON_EXPAND,
  CL_BUTTON_VARIANT,
  CL_NOTIFICATION_VARIANT,
  CL_SIZE,
  ClButtonModule,
  ClButtonSize,
  ClInputModule,
  ClNotificationMessageModule,
} from '@circlek-global/eca-lightning-components';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { nameValidator, phoneValidator } from 'src/helpers/validators';
import { ApiService, Region } from '../api/api.service';
import { LoaderComponent } from '../components/loader/loader.component';
import { LoaderService } from '../components/loader/loader.service';
import { ToastService } from '../components/toast/toast.service';
import { UserData } from '../state/user/user.model';
import { selectUserData } from '../state/user/user.selectors';

import reportSentryError from "../../helpers/reportSentryError";

@Component({
  selector: 'app-user-data-form',
  standalone: true,
  imports: [
    TranslateModule,
    ClButtonModule,
    ClInputModule,
    ClNotificationMessageModule,
    ReactiveFormsModule,
    LoaderComponent,
  ],
  templateUrl: './user-data-form.component.html',
})
export class UserDataFormComponent implements OnInit {
  private store = inject(Store);
  private formBuilder = inject(FormBuilder);
  private router = inject(Router);
  private httpClient = inject(HttpClient);
  private apiService = inject(ApiService);
  private loader = inject(LoaderService);
  private destroyRef = inject(DestroyRef);
  private translate = inject(TranslateService);
  private toast = inject(ToastService);

  user = signal<UserData | undefined>(undefined);

  closeDataChangeModeButtonVariants: CL_BUTTON_VARIANT = CL_BUTTON_VARIANT.SECONDARY;
  editDataButtonSize: ClButtonSize = CL_SIZE.XXL;
  editDataButtonExpand: CL_BUTTON_EXPAND = CL_BUTTON_EXPAND.FULL;
  notificationErrorVariant: CL_NOTIFICATION_VARIANT = CL_NOTIFICATION_VARIANT.ERROR;

  @Output() verifyOtp = new EventEmitter<boolean>();

  isEuForm = signal(this.apiService.region() !== Region.US);
  changeDataForm!: FormGroup;

  ngOnInit() {
    this.getUserData();
  }

  getUserData() {
    this.store.select(selectUserData).subscribe((userData: UserData | null) => {
      this.user.set(userData || {});
      this.changeDataForm = this.formBuilder.group({
        email: [userData?.email, [Validators.required, Validators.email]],
        phone: [userData?.phone, [Validators.required, phoneValidator()]],
      });

      if (this.isEuForm()) {
        this.changeDataForm.addControl(
          'firstName',
          this.formBuilder.control(userData?.firstName, [Validators.required, nameValidator()]),
        );
        this.changeDataForm.addControl(
          'lastName',
          this.formBuilder.control(userData?.lastName, [Validators.required, nameValidator()]),
        );
      }
    });
  }

  toggleEditMode() {
    this.router.navigate([this.translate.currentLang, 'profile']);
  }

  onChangeDataSubmit(): void {
    this.loader.setLoader(true);
    const changes: Partial<UserData> = {};

    const formValues = this.changeDataForm.value;
    const user = this.user() ?? {};

    Object.keys(formValues).forEach((field: string) => {
      const newValue = formValues[field];

      if (newValue !== user[field as keyof UserData]) {
        changes[field as keyof UserData] = newValue;
      }
    });

    const phoneChanged = changes.phone !== undefined;
    const emailChanged = changes.email !== undefined;

    if (this.isEuForm()) {
      const lastNameChanged = changes.lastName !== undefined;
      const firstNameChanged = changes.firstName !== undefined;

      if ((phoneChanged || emailChanged) && (!firstNameChanged || !lastNameChanged)) {
        changes.firstName = user?.firstName;
      }
    }

    const subscription = this.httpClient
      .post(`${this.apiService.createBffUrl({ path: 'user', useRegion: true })}/update`, changes, {
        withCredentials: true,
      })
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: () => {
          if (changes.phone) {
            this.verifyOtp.emit(true);
          } else {
            this.toast.showSuccess('PERSONAL_DATA_CHANGED');
            this.toggleEditMode();
          }
        },
        error: (error) => {
          if (error.error.path === '/v1/me') {
            this.toast.showError('user_email_already_exists');
          } else {
            reportSentryError({
              error,
              customErrorName: 'On Change Data Submit Error',
              endpointName: 'on-change-data-submit',
            });

            this.toast.showError(error.error.errorCode);
          }
          console.error({ error });
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }
}
