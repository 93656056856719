import { Component } from '@angular/core';

import { ClIconModule } from "@circlek-global/eca-lightning-components";
import {TranslateModule} from "@ngx-translate/core";

import { CL_ICON, CL_SIZE } from "@circlek-global/eca-lightning-components";
import { footerLinks } from "../../../helpers/constants";

@Component({
  selector: 'app-footer',
  standalone: true,
    imports: [ClIconModule, TranslateModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  CL_ICON = CL_ICON;
  CL_SIZE = CL_SIZE;
  footerLinks = footerLinks;
}
