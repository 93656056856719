import {Action, createReducer, on} from '@ngrx/store';
import { setUserData, clearUserData } from './user.actions';
import { UserData } from './user.model';

export interface UserState {
  data: UserData | null;
}

export const initialState: UserState = {
  data: null,
};

export const _userReducer = createReducer(
  initialState,
  on(setUserData, (state, { data }) => ({ ...state, data })),
  on(clearUserData, (state) => ({ ...state, data: null }))
);

export function userReducer(state: UserState | undefined, action: Action) {
  return _userReducer(state, action);
}
