<div
  class="cl-ui-components cl-flex cl-flex-col cl-max-w-[1217px] cl-pb-4 md:cl-pb-8 cl-mx-auto cl-w-full"
  [formGroup]="verifyOTP"
>
  <div class="cl-mb-3 otp-wrapper">
    <cl-input
      formControlName="passCode"
      placeholder="{{ 'verify_otp_label' | translate }}"
      label="{{ 'verify_otp' | translate }}"
      errorHelpText="{{ 'verify_otp_error' | translate }}"
    ></cl-input>
  </div>
  <div class="cl-flex cl-justify-between">
    <cl-button
      (click)="toggleEditMode()"
      [expand]="editDataButtonExpand"
      [size]="editDataButtonSize"
      [variant]="closeDataChangeModeButtonVariants"
    >
      {{ 'CLOSE_EDIT_DATA_BUTTON' | translate }}
    </cl-button>
    <cl-button
      (click)="onVerifyCodeSubmit()"
      [expand]="editDataButtonExpand"
      [disabled]="!verifyOTP.valid"
      [size]="editDataButtonSize"
    >
      <p class="cl-text-fg-white">
        {{ 'verify_phone' | translate }}
      </p>
    </cl-button>
  </div>
</div>
