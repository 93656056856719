import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Component, DestroyRef, inject, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  CL_BUTTON_EXPAND,
  CL_BUTTON_VARIANT,
  CL_SIZE,
  ClButtonModule,
  ClButtonSize,
  ClInputModule,
} from '@circlek-global/eca-lightning-components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { passwordValidator } from 'src/helpers/validators';
import { parseErrorMessageToError } from '../../helpers/parseErrorMessageToError';
import { ApiService } from '../api/api.service';
import { LayoutComponent } from '../components/layout/layout.component';
import { LoaderService } from '../components/loader/loader.service';
import { PasswordStrengthMeterComponent } from '../components/password-strength-meter/password-strength-meter.component';
import {ToastService} from "../components/toast/toast.service";

import reportSentryError from "../../helpers/reportSentryError";

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    LayoutComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterComponent,
    ClButtonModule,
    ClInputModule,
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
  private httpClient = inject(HttpClient);
  private destroyRef = inject(DestroyRef);
  private apiService = inject(ApiService);
  private translate = inject(TranslateService);
  private router = inject(Router);
  private toast = inject(ToastService);
  private formBuilder = inject(FormBuilder);
  private loader = inject(LoaderService);

  buttonSize: ClButtonSize = CL_SIZE.XL;
  buttonExpand: CL_BUTTON_EXPAND = CL_BUTTON_EXPAND.CONTENT;
  cancelButtonVariant: CL_BUTTON_VARIANT = CL_BUTTON_VARIANT.TERTIARY;

  passwordForm!: FormGroup;
  newPassword = signal('');

  ngOnInit() {
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, passwordValidator()]],
    });

    this.passwordForm.controls['newPassword'].valueChanges.subscribe((value) => {
      this.newPassword.set(value);
    });
  }

  onSubmit() {
    if (this.passwordForm.status !== 'VALID') return;
    this.loader.setLoader(true);

    const subscription = this.httpClient
      .post(
        `${this.apiService.createBffUrl({ path: 'user', useRegion: true })}/change-password`,
        this.passwordForm.value,
        {
          withCredentials: true,
        },
      )
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: () => {
          this.toast.showSuccess('PASSWORD_CHANGED');
          this.goBack();
        },
        error: (error: HttpErrorResponse) => {
          reportSentryError({
            error,
            customErrorName: 'Password Change Error',
            endpointName: 'change-password',
          });

          console.error({ error });

          if(error.error.errorCode === 'okta_exception') {
            this.toast.showError(parseErrorMessageToError(error.error.reason));
          } else {
            this.toast.showError('something_went_wrong');
          }
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  onCancel(e: Event) {
    e.preventDefault(); // just because LDS input doesn't accept type attr and button is 'submit' by default
    this.goBack();
  }

  private goBack() {
    this.router.navigate([this.translate.currentLang, 'profile']);
  }
}
