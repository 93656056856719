import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordSchema: Record<string, RegExp> = {
  length: /.{8,}/,
  upperCaseLetter: /[A-Z]/,
  lowerCaseLetter: /[a-z]/,
  number: /\d/,
};
const namePattern = /^[a-z\u00C0-\u017F]+(-[a-z\u00C0-\u017F]+)?$/i;
const phonePattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const errorsArray: string[][] = [];

    Object.keys(passwordSchema).forEach((key) => {
      if (!passwordSchema[key].test(control.value)) errorsArray.push([key, 'INVALID']);
    });

    return errorsArray.length ? Object.fromEntries(errorsArray) : null;
  };
}

const genericValidator = (pattern: RegExp, validatorName: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return pattern.test(control.value) ? null : { [validatorName]: 'INVALID' };
  };
};

export const nameValidator = () => genericValidator(namePattern, 'namePattern');
export const phoneValidator = () => genericValidator(phonePattern, 'phonePattern');
