import {Injectable, signal} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  isDisabled = signal(false);

  disableLinks() {
    this.isDisabled.set(!this.isDisabled());
  }
}
