export const languages = [
  { code: 'en', name: 'ENGLISH', flag: 'en.png' },
  { code: 'da', name: 'DANSK', flag: 'da.png' },
  { code: 'et', name: 'EESTI KEEL', flag: 'et.png' },
  { code: 'lt', name: 'LIETUVIŲ', flag: 'lt.png' },
  { code: 'lv', name: 'LATVIEŠU', flag: 'lv.png' },
  { code: 'no', name: 'NORSK(BOKMÅL)', flag: 'no.png' },
  { code: 'pl', name: 'POLSKI', flag: 'pl.png' },
  { code: 'sv', name: 'SVENSKA', flag: 'sv.png' },
];
