import {Component, inject, signal} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import { OtpFormComponent } from '../otp-form/otp-form.component';
import { UserDataFormComponent } from '../user-data-form/user-data-form.component';
import {UserData} from "../state/user/user.model";
import {FormGroup} from "@angular/forms";
import {ToastService} from "../components/toast/toast.service";
import {Router} from "@angular/router";
import {UpdateProfileService} from "../api/update-profile.service";

@Component({
  selector: 'app-change-profile-data',
  standalone: true,
  imports: [UserDataFormComponent, OtpFormComponent, TranslateModule],
  templateUrl: './change-profile-data.component.html',
})
export class ChangeProfileDataComponent {
  private router = inject(Router);
  private translate = inject(TranslateService);
  private updateProfileService = inject(UpdateProfileService);
  private toast = inject(ToastService);

  verifyOtp = signal(false);

  closeButtonTransKey = 'CLOSE_EDIT_DATA_BUTTON';
  submitButtonTransKey = 'update_data';

  toggleEditMode() {
    this.router.navigate([this.translate.currentLang, 'profile']);
  }

  onDataChangeSuccess() {
    this.toast.showSuccess('USER_PROFILE_CONFIRMED');
    this.router.navigate([this.translate.currentLang, 'profile']);
  }

  onChangeDataSubmit(changeDataForm: FormGroup, userData: UserData | undefined): void {
    this.updateProfileService.updateProfile(changeDataForm, userData, this.verifyOtp, this.onDataChangeSuccess.bind(this));
  }
}
