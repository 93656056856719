import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  isLoading = signal(false);

  setLoader(flag: boolean) {
    this.isLoading.set(flag);
  }
}
