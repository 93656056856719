<footer class="cl-bg-[#F7F5F2] cl-text-[#666] cl-w-full cl-mt-10">
  <div class="cl-max-w-[75rem] cl-w-full cl-mx-auto cl-py-4">
    <nav
      class="cl-flex cl-px-1 cl-flex-col lg:cl-flex-row cl-items-center cl-justify-start cl-border-b cl-border-[#EEEEEE] cl-pb-4 cl-mb-4"
    >
      <div class="cl-flex cl-flex-row cl-justify-center cl-items-center cl-space-x-4 cl-mb-1 lg:cl-mb-0">
        <a href="#" class="cl-text-[#666] hover:cl-text-[#000]"
          ><cl-icon [name]="CL_ICON.HEART_CIRCLE" [size]="CL_SIZE.MD"></cl-icon
        ></a>
        <a href="#" class="cl-text-[#666] hover:cl-text-[#000]"
          ><cl-icon [name]="CL_ICON.HEART_CIRCLE" [size]="CL_SIZE.MD"></cl-icon
        ></a>
        <a href="#" class="cl-text-[#666] hover:cl-text-[#000]"
          ><cl-icon [name]="CL_ICON.HEART_CIRCLE" [size]="CL_SIZE.MD"></cl-icon
        ></a>
        <a href="#" class="cl-text-[#666] hover:cl-text-[#000]"
          ><cl-icon [name]="CL_ICON.HEART_CIRCLE" [size]="CL_SIZE.MD"></cl-icon
        ></a>
      </div>

      <div
        class="cl-flex cl-flex-col lg:cl-flex-row cl-items-center lg:cl-space-x-2 cl-space-y-2 lg:cl-space-y-0 lg:cl-ml-auto"
      >
        <a
          href="#"
          class="cl-py-1 cl-px-2 cl-rounded cl-font-light hover:cl-bg-blue-600 hover:cl-text-fg-brand-secondary"
          >FAQ</a
        >
        <a
          href="#"
          class="cl-py-1 cl-px-2 cl-rounded cl-font-light hover:cl-bg-blue-600 hover:cl-text-fg-brand-secondary"
          >{{ 'HOW_WE_USE_YOUR_DATA' | translate }}</a
        >
        <a
          href="#"
          class="cl-py-1 cl-px-2 cl-rounded cl-font-light hover:cl-bg-blue-600 hover:cl-text-fg-brand-secondary"
          >{{ 'PRIVACY_STATEMENT' | translate }}</a
        >
        <a
          href="#"
          class="cl-py-1 cl-px-2 cl-rounded cl-font-light hover:cl-bg-blue-600 hover:cl-text-fg-brand-secondary"
          >{{ 'TERMS_OF_USE' | translate }}</a
        >
        <a
          href="#"
          class="cl-py-1 cl-px-2 cl-rounded cl-font-light hover:cl-bg-blue-600 hover:cl-text-fg-brand-secondary"
          >{{ 'CONTACT_US' | translate }}</a
        >
        <a
          href="#"
          class="cl-py-1 cl-px-2 cl-rounded cl-font-light hover:cl-bg-blue-600 hover:cl-text-fg-brand-secondary"
          >{{ 'COOKIE_POLICY' | translate }}</a
        >
      </div>
    </nav>

    <div class="cl-text-center cl-text-[0.8125rem] cl-font-light">Copyright &copy; 2024 Circle K</div>
  </div>
</footer>
