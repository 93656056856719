import {Component, DestroyRef, inject, OnInit, signal} from '@angular/core';
import {LayoutComponent} from "../components/layout/layout.component";
import { CL_ICON, CL_SIZE, ClTableModule } from "@circlek-global/eca-lightning-components";
import { ClIconModule } from "@circlek-global/eca-lightning-components";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../auth/auth.service";
import {ApiService} from "../api/api.service";
import {TranslateModule} from "@ngx-translate/core";
import {LoaderService} from "../components/loader/loader.service";
import {finalize} from "rxjs";
import {ToastService} from "../components/toast/toast.service";

import reportSentryError from "../../helpers/reportSentryError";

type DataSourceTermsType = {
  "codeName": string;
  "countryCode": string
  "version": number;
  "status": string;
  "url": string;
}

@Component({
  selector: 'app-user-terms',
  standalone: true,
  imports: [ClTableModule, LayoutComponent, ClIconModule, TranslateModule],
  templateUrl: './user-terms.component.html',
})
export class UserTermsComponent implements OnInit {
  private httpClient = inject(HttpClient);
  private destroyRef = inject(DestroyRef);
  private authService = inject(AuthService);
  private apiService = inject(ApiService);
  private toast = inject(ToastService);

  loader = inject(LoaderService);

  termsDataSource = signal<DataSourceTermsType[]>([]);

  trashIcon = CL_ICON.TRASH_01;
  linkIcon = CL_ICON.LINK_01;
  CL_SIZE = CL_SIZE;


  ngOnInit(): void {
    this.getUserTerms();
  }

  getUserTerms() {
    this.loader.setLoader(true);
    const subscription = this.httpClient
      .get<DataSourceTermsType[]>(`${this.apiService.createBffUrl({ path: 'terms', useRegion: true })}`, {
        withCredentials: true,
      })
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: (responseData) => {
          this.termsDataSource.set(responseData);
        },
        error: (error) => {
          reportSentryError({
            error,
            customErrorName: 'Get User Terms Error',
            endpointName: 'get-user-terms',
          });

          console.error({ error });
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }

  deleteTerms(terms: string, country: string) {
    this.loader.setLoader(true);

    const subscription = this.httpClient
      .delete(`${this.apiService.createBffUrl({ path: 'terms', useRegion: true })}`, {
        withCredentials: true,
        body: {
          codeName: terms,
          countryCode: country
        }
      })
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: () => {
          if(terms == 'terms_extra') {
            this.authService.logout();
            return;
          }

          this.termsDataSource.update(
            (items: DataSourceTermsType[]) => items.filter((item: DataSourceTermsType) => item.codeName !== terms)
          );

          this.toast.showSuccess('TERMS_DELETE_SUCCESS');
        },
        error: (error) => {
          reportSentryError({
            error,
            customErrorName: 'Delete User Terms Error',
            endpointName: 'delete-user-terms',
          });

          console.error({ error });
          this.toast.showError('something_went_wrong');
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }
}
