import { Component, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutComponent } from '../components/layout/layout.component';
import { OtpFormComponent } from '../otp-form/otp-form.component';
import { UserDataFormComponent } from '../user-data-form/user-data-form.component';

@Component({
  selector: 'app-change-profile-data',
  standalone: true,
  imports: [LayoutComponent, UserDataFormComponent, OtpFormComponent, TranslateModule],
  templateUrl: './change-profile-data.component.html',
})
export class ChangeProfileDataComponent {
  private verifyOtp = signal(false);

  setVerifyOtp(isOtpVerified: boolean) {
    this.verifyOtp.set(isOtpVerified);
  }

  get isVerifyOtp() {
    return this.verifyOtp();
  }
}
