import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, inject, OnInit, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, firstValueFrom, take } from 'rxjs';
import { ApiService, Region } from './api/api.service';
import { AuthComponent } from './auth/auth.component';
import { AuthService } from './auth/auth.service';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

import reportSentryError from "../helpers/reportSentryError";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HttpClientModule,
    HeaderComponent,
    FooterComponent,
    AuthComponent,
    UserProfileComponent,
    LoaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private authService = inject(AuthService);
  private apiService = inject(ApiService);
  private router = inject(Router);
  private translate = inject(TranslateService);

  constructor(private route: ActivatedRoute) {}

  isReady = signal(false)
  async ngOnInit(): Promise<void> {
    await firstValueFrom(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      ))
      .then(() => {
        const region = this.route.snapshot.queryParamMap.get('region');
        if (!region) {
          this.apiService.setRegion(Region.EU);
        } else {
          this.apiService.setRegion(region as Region);
        }

        this.authService.checkIfAuthorized();
      }).catch((error) => {
        reportSentryError({
          error,
          customErrorName: 'App Component Error',
        });
      });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe({
      next: () => {
        let route = this.router.routerState.snapshot.root;
        let lang: string | null;

        while (route.firstChild) {
          route = route.firstChild;
        }

        lang = route.params['lang'];

        if (lang) {
          this.translate.use(lang);
        } else {
          this.router.navigate(['/en/profile']);
        }
      },
      error: (error) => {
        reportSentryError({
          error,
          customErrorName: 'App Component Error',
        });
      }
    });

    this.isReady.set(true);
  }
}
