<app-modal
  [modalType]="CL_INFO_MODAL_TYPES.ERROR"
  [titleTransKey]="'REMOVE_TERMS_MODAL_TITLE'"
  [infoTextTransKey]="'REMOVE_TERMS_MODAL_TEXT'"
  [confirmBtnTextTransKey]="'REMOVE_TERMS_MODAL_YES_BUTTON'"
  [cancelBtnTextTransKey]="'REMOVE_TERMS_MODAL_NO_BUTTON'"
  [isCancelBtnHidden]="false"
  (onButtonClose)="onButtonClose()"
  (onButtonConfirm)="onButtonConfirm()"
/>
@if (!loader.isLoading()) {
  <div class="cl-container cl-mx-auto cl-mt-32 cl-mb-32">
    <div class="cl-ui-components cl-flex cl-justify-center">
      <cl-table [dataSource]="termsDataSource()" class="xxl:cl-w-3/4 cl-w-full">
        <ng-template clTableHeader>
          <th cl-sort>
            <div class="cl-text-body-medium-2">{{ 'DESCRIPTION' | translate }}</div>
          </th>
          <th>{{ 'DELETE' | translate }}</th>
        </ng-template>
        <ng-template clTableRow let-item="item">
          <td>
            <a href="{{ item.url }}" target="”_blank”">
              <div class="cl-text-body-medium-2 cl-text-fg-primary cl-underline cl-flex">
                @if (item.description) {
                  <span class="cl-mr-1">{{ item.description }}</span>
                } @else {
                  <span class="cl-mr-1">{{ 'TERMS_AND_CONDITIONS' | translate }}</span>
                }
                <cl-icon [name]="linkIcon" [size]="CL_SIZE.MD"></cl-icon>
              </div>
            </a>
          </td>
          <td>
            <p class="cl-cursor-pointer" (click)="openDeleteModal(item.codeName, item.countryCode)">
              <cl-icon [name]="trashIcon" [size]="CL_SIZE.MD"></cl-icon>
            </p>
          </td>
        </ng-template>
      </cl-table>
    </div>
  </div>
}
