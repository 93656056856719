<app-layout>
  @if (error()) {
    <div class="cl-container cl-mx-auto cl-max-w-xl cl-mt-32 cl-mb-32">
      <h1>{{ 'SOMETHING_WENT_WRONG' | translate }}</h1>
      <cl-button (click)="tryAgain()">
        {{ 'TRY_AGAIN' | translate }}
      </cl-button>
    </div>
  }
  @if (!error() && !loader.isLoading()) {
    <div class="cl-container cl-mx-auto cl-max-w-xl cl-mt-32 cl-mb-32">
      <div class="cl-grid cl-justify-center">
        <h2 class="cl-text-display-lg cl-mb-12">{{ 'PERSONAL_DATA_TITLE' | translate }}</h2>
        <div class="cl-mb-8">
          @if (region === 'eu') {
            <p class="cl-mb-4">
              <strong>
                {{ 'FIRST_NAME_AND_SURNAME_TEXT' | translate }}
              </strong>
            </p>
            <p>{{ user()?.firstName }} {{ user()?.lastName }}</p>
          }
          <p class="cl-mb-4 cl-mt-6">
            <strong>{{ 'EMAIL_TEXT' | translate }}</strong>
          </p>
          <p>{{ user()?.email }}</p>
          <p class="cl-mb-4 cl-mt-6">
            <strong>{{ 'PHONE_NUMBER_TEXT' | translate }}</strong>
          </p>
          <p>{{ user()?.phone }}</p>
        </div>
        <cl-button [expand]="editDataButtonExpand" [size]="editDataButtonSize" (click)="toggleEditMode()">
          <p class="cl-text-fg-white">
            {{ 'EDIT_DATA_BUTTON' | translate }}
          </p>
        </cl-button>
        <p class="cl-text-orange-50 cl-mt-6 cl-cursor-pointer" (click)="changePassword()">
          <strong>{{ 'CHANGE_PASSWORD_BUTTON' | translate }}</strong>
        </p>
      </div>
    </div>
  }
</app-layout>
<router-outlet />
