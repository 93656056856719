<app-layout>
  @if(!loader.isLoading()) {
    <div class="cl-container cl-mx-auto cl-mt-32 cl-mb-32">
      <div class="cl-ui-components cl-flex cl-justify-center">
        <cl-table [dataSource]="termsDataSource()" class="xxl:cl-w-3/4 cl-w-full">
          <ng-template clTableHeader>
            <th cl-sort>
              <div class="cl-text-body-medium-2">{{ 'NAME' | translate }}</div>
            </th>
            <th>{{ 'DESCRIPTION' | translate }}</th>
            <th>{{ 'DELETE' | translate }}</th>
          </ng-template>

          <ng-template clTableRow let-item="item">
            <td>
              <a href="{{item.url}}" target=”_blank”>
                <div class="cl-text-body-medium-2 cl-text-fg-primary cl-underline cl-flex">
                  <span class="cl-mr-1">{{ item.codeName }}</span>
                  <cl-icon [name]="linkIcon" [size]="CL_SIZE.MD"></cl-icon>
                </div>
              </a>
            </td>
            <td>TEST</td>
            <td>
              <p class="cl-cursor-pointer" (click)="deleteTerms(item.codeName, item.countryCode)">
                <cl-icon [name]="trashIcon" [size]="CL_SIZE.MD"></cl-icon>
              </p>
            </td>
          </ng-template>
        </cl-table>
      </div>
    </div>
  }
</app-layout>
