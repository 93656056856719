<div class="cl-container cl-mx-auto cl-max-w-xl cl-mt-32 cl-mb-32">
  <div class="cl-grid cl-justify-center">
    <h2 class="cl-text-display-lg cl-mb-12">{{ 'VERIFY_USER_PROFILE' | translate }}</h2>
    @if (!verifyOtp()) {
      <app-user-data-form
        (onSubmitButton)="onChangeDataSubmit($event.changeDataForm, $event.userData)"
        (onCloseButton)="logout()"
        [closeButtonTransKey]="closeButtonTransKey"
        [submitButtonTransKey]="submitButtonTransKey"
        [requiredDataChange]="false"
      ></app-user-data-form>
    } @else {
      <app-otp-form></app-otp-form>
    }
  </div>
</div>

