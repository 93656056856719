import { inject, Injectable, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService implements OnDestroy {
  private toastr = inject(ToastrService);
  private translate = inject(TranslateService);
  private subscription!: Subscription;

  private defaultToastConfig = {
    timeOut: 5000,
    positionClass: 'toast-bottom-center',
    closeButton: true,
  };

  private getTranslationWithFallback(mainKey: string, fallbackKey: string): Promise<string> {
    const mainTranslation = this.translate.instant(mainKey);

    return mainTranslation === mainKey
      ? this.translate.instant(fallbackKey)
      : mainTranslation;
  }

  async showError(messageTransKey: string) {
    const message = await this.getTranslationWithFallback(messageTransKey, 'something_went_wrong');

    this.toastr.error(message, '', this.defaultToastConfig);
  }

  async showSuccess(messageTransKey: string) {
    const message = await this.getTranslationWithFallback(messageTransKey, 'DEFAULT_SUCCESS_KEY');

    this.toastr.success(message, '', this.defaultToastConfig);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
