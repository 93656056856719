import { Routes } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { canActivateAuth } from './auth/auth.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangeProfileDataComponent } from './change-profile-data/change-profile-data.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserTermsComponent } from './user-terms/user-terms.component';

export const routes: Routes = [
  {
    path: ':lang/profile',
    component: UserProfileComponent,
    canActivate: [canActivateAuth],
  },
  {
    path: ':lang/profile/change-data',
    component: ChangeProfileDataComponent,
    canActivate: [canActivateAuth],
  },
  {
    path: ':lang/profile/change-password',
    component: ChangePasswordComponent,
    canActivate: [canActivateAuth],
  },
  {
    path: ':lang/terms',
    component: UserTermsComponent,
    canActivate: [canActivateAuth],
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
];
