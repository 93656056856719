<mat-select
  [(value)]="selectedLanguage"
  (selectionChange)="onLanguageChange($event)"
  class="cl-text-[0.8125rem] hover:cl-text-fg-brand-secondary cl-h-[2rem] cl-text-[#000000] cl-flex cl-items-center cl-pl-2 cl-pr-2 cl-uppercase cl-font-light cl-text-small-screen sm:cl-text-large-screen"
>

  @for(language of languages; track language.code) {
    <mat-option
      [value]="language.code"
      class="cl-flex cl-items-center cl-text-[12px] cl-uppercase"
    >
      <div class="cl-flex cl-items-center cl-py-[10px] cl-px-[20px] cl-w-full">
        <img
          src="assets/images/{{ language.flag }}"
            alt="{{ language.name }} flag"
            class="cl-w-4 cl-h-4 cl-mr-2 cl-inline-block"
          />
          <span class="cl-inline-block">{{ language.name }}</span>
        </div>
      </mat-option>
  }

</mat-select>
