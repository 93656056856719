import { Injectable, inject, signal } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

export enum Region {
  US = 'us',
  EU = 'eu',
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  region = signal<Region | undefined>(undefined);
  isEuForm = signal(this.region() !== Region.US);

  setRegion(region: Region) {
    this.region.set(region);
  }

  createBffUrl({ path, useRegion }: { path: string; useRegion: boolean }) {
    return `${environment.bffAddress}/${path}${useRegion ? `/${this.region()}` : ''}`;
  }
}
