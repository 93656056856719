<p>Password strength</p>
<div class="cl-flex cl-h-3 cl-gap-x-2 cl-mb-3">
  <div class="cl-flex-1 cl-h-full strenth-indicator weak" [class.active]="passwordStrengthLevel === 0"></div>
  <div class="cl-flex-1 cl-h-full strenth-indicator medium" [class.active]="passwordStrengthLevel === 1"></div>
  <div class="cl-flex-1 cl-h-full strenth-indicator strong" [class.active]="passwordStrengthLevel === 2"></div>
</div>
<div class="cl-min-h-20">
  @if (passwordStrengthLevel === 0) {
    <p>{{ 'WEAK_PASSWORD' | translate }}</p>
    <p>{{ 'PASSWORD_REQUIREMENTS_INFO' | translate }}</p>
  } @else if (passwordStrengthLevel === 1) {
    <p>{{ 'MEDIUM_PASSWORD' | translate }}</p>
  } @else if (passwordStrengthLevel === 2) {
    <p>{{ 'STRONG_PASSWORD' | translate }}</p>
  }
</div>
