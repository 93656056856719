export const languages = [
  { code: 'en', name: 'ENGLISH', flag: 'en.png' },
  { code: 'da', name: 'DANSK', flag: 'da.png' },
  { code: 'et', name: 'EESTI KEEL', flag: 'et.png' },
  { code: 'lt', name: 'LIETUVIŲ', flag: 'lt.png' },
  { code: 'lv', name: 'LATVIEŠU', flag: 'lv.png' },
  { code: 'no', name: 'NORSK(BOKMÅL)', flag: 'no.png' },
  { code: 'pl', name: 'POLSKI', flag: 'pl.png' },
  { code: 'sv', name: 'SVENSKA', flag: 'sv.png' },
];

export const footerLinks = {
  faq: 'https://id.circlekeurope.com/#/legal/faq',
  how_we_use_your_data: 'https://id.circlekeurope.com/#/legal/dataUse',
  privacy_statement: 'https://id.circlekeurope.com/#/legal/privacy-2022-01',
  terms_of_use: 'https://id.circlekeurope.com/#/legal/terms',
  contact_us: 'https://www.circlek.eu/contact-us',
  cookie_policy: 'https://id.circlekeurope.com/#/legal/cookies'
}
