import {Component, effect, ElementRef, inject, Renderer2} from '@angular/core';
import {FooterComponent} from "../footer/footer.component";
import {HeaderComponent} from "../header/header.component";
import {LayoutService} from "./layout.service";

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [FooterComponent, HeaderComponent],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  private renderer = inject(Renderer2);
  private elRef = inject(ElementRef);
  private layout = inject(LayoutService);

  private clickListeners: ((() => void) | null)[] = [];

  constructor() {
    effect(() => {
      const links = this.elRef.nativeElement.querySelectorAll('a');
      if(this.layout.isDisabled()){
        links.forEach((link: HTMLAnchorElement, index: number) => {
          this.renderer.setAttribute(link, 'disabled', 'true');
          this.clickListeners[index] = this.renderer.listen(link, 'click', (event) => event.preventDefault());
        });
      } else {
        links.forEach((link: HTMLAnchorElement, index: number) => {
          this.renderer.removeAttribute(link, 'disabled', 'true');
          if (this.clickListeners[index]) {
            this.clickListeners[index]!();
            this.clickListeners[index] = null;
          }
        });
      }
    })
  }
}
