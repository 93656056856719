<header class="cl-pl-2 cl-pr-2">
  <div class="cl-w-full cl-border-b cl-border-[#EEEEEE]">
    <div class="cl-w-full">
      <nav
        class="cl-max-w-[75rem] cl-w-full cl-flex cl-justify-end cl-items-center cl-mx-auto cl-font-light cl-uppercase cl-text-small-screen sm:cl-text-large-screen"
      >
        <div class="cl-border-r cl-border-[#EEEEEE]">
          <app-language-selector></app-language-selector>
        </div>
        <span class="cl-py-2 cl-px-4 cl-border-r cl-border-[#EEEEEE] hover:cl-text-fg-brand-secondary cl-cursor-default">
          {{ 'HELLO' | translate }}
          @if (region === 'eu') {
            , {{ userName() }}
          } @else {
            !
          }
        </span>
        <span (click)="logout()" class="cl-py-2 cl-px-4 hover:cl-text-fg-brand-secondary cl-cursor-pointer">
          {{ 'LOGOUT' | translate }}
        </span>
      </nav>
    </div>
  </div>

  <div class="cl-w-full cl-py-4 cl-text-[13px] cl-font-bold">
    <div class="cl-max-w-[75rem] cl-w-full cl-mx-auto">
      <div class="cl-flex cl-items-center cl-justify-between">
        <div class="cl-flex cl-items-center">
          <cl-logo></cl-logo>
        </div>

        <nav class="cl-flex cl-items-center cl-font-light cl-uppercase cl-text-small-screen sm:cl-text-large-screen">
          <a
            [routerLink]="['/' + currentLang(), 'profile']"
            [ngClass]="{ 'cl-text-fg-brand-secondary': isActive('/profile') }"
            class="cl-py-2 cl-px-4 cl-font-bold hover:cl-text-fg-brand-secondary"
          >
            {{ 'MY_PROFILE' | translate }}
          </a>
          <a
            [routerLink]="['/' + currentLang(), 'terms']"
            [ngClass]="{ 'cl-text-fg-brand-secondary': isActive('/terms') }"
            class="cl-py-2 cl-px-4 cl-font-bold hover:cl-text-fg-brand-secondary"
          >
            {{ 'MY_TERMS' | translate }}
          </a>
        </nav>
      </div>
    </div>
  </div>
</header>
