import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CL_BUTTON_EXPAND,
  CL_BUTTON_VARIANT,
  CL_NOTIFICATION_VARIANT,
  CL_SIZE,
  ClButtonModule,
  ClButtonSize,
  ClInputModule,
  ClNotificationMessageModule,
} from '@circlek-global/eca-lightning-components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../components/loader/loader.service';
import { finalize } from 'rxjs';
import { ToastService } from '../components/toast/toast.service';

import reportSentryError from '../../helpers/reportSentryError';

@Component({
  selector: 'app-otp-form',
  standalone: true,
  imports: [TranslateModule, ClButtonModule, ClInputModule, ClNotificationMessageModule, ReactiveFormsModule],
  templateUrl: './otp-form.component.html',
})
export class OtpFormComponent implements OnInit {
  private httpClient = inject(HttpClient);
  private destroyRef = inject(DestroyRef);
  private apiService = inject(ApiService);
  private formBuilder = inject(FormBuilder);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private loader = inject(LoaderService);
  private translate = inject(TranslateService);
  private toast = inject(ToastService);

  closeDataChangeModeButtonVariants: CL_BUTTON_VARIANT = CL_BUTTON_VARIANT.SECONDARY;
  editDataButtonSize: ClButtonSize = CL_SIZE.XXL;
  editDataButtonExpand: CL_BUTTON_EXPAND = CL_BUTTON_EXPAND.FULL;
  notificationErrorVariant: CL_NOTIFICATION_VARIANT = CL_NOTIFICATION_VARIANT.ERROR;

  verifyOTP!: FormGroup;

  ngOnInit() {
    this.verifyOTP = this.formBuilder.group({
      passCode: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
    });
  }

  toggleEditMode() {
    this.router.navigate([this.translate.currentLang, 'profile']);
  }

  onVerifyCodeSubmit() {
    this.loader.setLoader(true);
    const subscription = this.httpClient
      .post(
        `${this.apiService.createBffUrl({ path: 'user', useRegion: true })}/verify-otp`,
        {
          passCode: this.verifyOTP.value['passCode'],
        },
        { withCredentials: true },
      )
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: () => {
          this.toast.showSuccess('PERSONAL_DATA_CHANGED');
          this.toggleEditMode();
        },
        error: (error) => {
          reportSentryError({
            error,
            customErrorName: 'On Verify Code Submit Error',
            endpointName: 'on-verify-code-submit',
          });
          this.toast.showError(error.error.errorCode);
          console.error({ error });
        },
      });

    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }
}
