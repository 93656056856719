<div class="cl-container cl-mx-auto cl-max-w-xl cl-mt-32 cl-mb-32">
  <div class="cl-flex cl-flex-col">
    <h2 class="cl-text-display-lg cl-mb-12">{{ 'CHANGE_PASSWORD' | translate }}</h2>
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="cl-flex cl-flex-col cl-gap-y-4">
      <cl-password-input
        name="old_password"
        label="{{ 'OLD_PASSWORD' | translate }}"
        id="old_password"
        formControlName="oldPassword"
      />
      <cl-password-input
        name="new_password"
        label="{{ 'NEW_PASSWORD' | translate }}"
        id="new_password"
        formControlName="newPassword"
      />

      <app-password-strength-meter [password]="newPassword()" />

      <div class="cl-flex cl-justify-end cl-gap-x-2">
        <cl-button
          [expand]="buttonExpand"
          [size]="buttonSize"
          [disabled]="!passwordForm.valid"
          class="cl-text-fg-white"
        >
          {{ 'SUBMIT' | translate }}</cl-button
        >
        <cl-button
          [expand]="buttonExpand"
          [size]="buttonSize"
          [variant]="cancelButtonVariant"
          (click)="onCancel($event)"
        >
          {{ 'CANCEL' | translate }}</cl-button
        >
      </div>
    </form>
  </div>
</div>
