import { Component, DestroyRef, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { UserDataFormComponent } from '../user-data-form/user-data-form.component';
import { OtpFormComponent } from '../otp-form/otp-form.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../components/loader/loader.service';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api/api.service';
import { ToastService } from '../components/toast/toast.service';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { UserData } from '../state/user/user.model';
import { finalize } from 'rxjs';
import { LayoutService } from '../components/layout/layout.service';
import { AuthService } from '../auth/auth.service';
import { UpdateProfileService } from '../api/update-profile.service';
import reportSentryError from "../../helpers/reportSentryError";

@Component({
  selector: 'app-confirm-user-profile',
  standalone: true,
  imports: [UserDataFormComponent, OtpFormComponent, TranslateModule],
  templateUrl: './confirm-user-profile.component.html',
})
export class ConfirmUserProfileComponent implements OnInit, OnDestroy {
  private loader = inject(LoaderService);
  private httpClient = inject(HttpClient);
  private apiService = inject(ApiService);
  private toast = inject(ToastService);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private layoutService = inject(LayoutService);
  private authService = inject(AuthService);
  private updateProfileService = inject(UpdateProfileService);

  skipDestroyActions = false;

  verifyOtp = signal(false);

  closeButtonTransKey = 'CLOSE_EDIT_DATA_BUTTON';
  submitButtonTransKey = 'CONFIRM_DATA';

  ngOnInit() {
    this.layoutService.disableLinks();
  }

  logout() {
    this.skipDestroyActions = true;
    this.authService.logout();
  }

  onDataChangeSuccess() {
    this.toast.showSuccess('USER_PROFILE_CONFIRMED');
    this.router.navigate([this.translate.currentLang, 'profile']);
  }

  onChangeDataSubmit(changeDataForm: FormGroup, userData: UserData | undefined): void {
    if (!changeDataForm.dirty) {
      this.onDataChangeSuccess();
      return;
    }

    this.updateProfileService.updateProfile(changeDataForm, userData, this.verifyOtp, this.onDataChangeSuccess.bind(this));
  }

  confirmUserData() {
    this.loader.setLoader(true);
    const subscription = this.httpClient
      .patch(
        `${this.apiService.createBffUrl({ path: 'user', useRegion: true })}/confirm-profile`,
        {},
        {
          withCredentials: true,
        },
      )
      .pipe(finalize(() => this.loader.setLoader(false)))
      .subscribe({
        next: () => {
          this.toast.showSuccess('USER_PROFILE_CONFIRMED');
          this.layoutService.disableLinks();
          this.onDataChangeSuccess();
        },
        error: (error) => {
          this.toast.showError('something_went_wrong');

          reportSentryError({
            error,
            customErrorName: 'Confirm User Profile',
            endpointName: 'confirm-user-profile',
          });

          console.error({error});
        },
      });
    this.destroyRef.onDestroy(() => {
      subscription.unsubscribe();
    });
  }
  ngOnDestroy() {
    if(!this.skipDestroyActions) {
      this.confirmUserData();
    }
  }
}
